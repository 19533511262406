import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Styles from './newsletterBanner.styles';
import { Col, Container, Row } from 'react-bootstrap';
import BackgroundImage from 'gatsby-background-image';
import * as Globals from '../../../globals';
import MailchimpForm from '../mailchimpForm/mailchimpForm';

export default function NewsletterBanner() {
  const data = useStaticQuery(graphql`
    query NewsletterBannerQuery {
      contentfulSiteBanners {
        newsletterSuperheadline
        newsletterHeadline
        newsletterButton
        newsletterBackground {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  let backgroundFluidImageStack = [
    data.contentfulSiteBanners.newsletterBackground.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Styles>
      <BackgroundImage
        fluid={backgroundFluidImageStack}
        className={`py-6`}
        Tag={`section`}
      >
        <Container style={{ zIndex: 1, position: 'relative' }}>
          <Row>
            <Col xs={12} md={8}>
              <h4 className={`mb-3`}>
                {data.contentfulSiteBanners.newsletterSuperheadline}
              </h4>
              <p className={`lead mb-4`}>
                {data.contentfulSiteBanners.newsletterHeadline}
              </p>

              <MailchimpForm
                confirmationMessage={'You are subscribed'}
                submitText={data.contentfulSiteBanners.newsletterButton}
              />
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </Styles>
  );
}
