import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import addToMailchimp from 'gatsby-plugin-mailchimp';

// Based on: https://github.com/benjaminhoffman/gatsby-plugin-mailchimp/blob/master/examples/src/pages/index.js
const MailchimpForm = ({ submitText, confirmationMessage }) => {
  const [state, setState] = React.useState({
    email: null,
  });
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const postSubmit = () => {
    setSubmitted(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log('submit', state);

    addToMailchimp(state.email, state)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`);
        if (result !== 'success') {
          throw msg;
        }
        postSubmit();
      })
      .catch((err) => {
        console.log('err', err);
        alert(err);
      });
  };

  return (
    <Form
      name="Newsletter Form"
      className="form-inline"
      onSubmit={handleSubmit}
    >
      <div className={`w-100`}>
        {!submitted && (
          <Row className={`no-gutters`}>
            <Col xs={12} md={7}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  required
                  onChange={handleChange}
                  className={`mailchimp-field w-100`}
                />
              </Form.Group>
              <small
                className={`d-block mt-2 mb-2 mb-md-0`}
                style={{ lineHeight: 1.2 }}
              >
                By signing up for our newsletter, you agree to our
                <br />
                <Link to="/terms/" activeClassName={`active`}>
                  Terms &amp; Conditions
                </Link>
              </small>
            </Col>
            <Col xs={10} md={5} className={`pl-3`}>
              <button
                type="submit"
                className="btn btn-primary d-block mailchimp-button w-100"
              >
                {submitText}
              </button>
            </Col>
          </Row>
        )}

        {submitted && <h2 className="text-center">{confirmationMessage}</h2>}
      </div>
    </Form>
  );
};

MailchimpForm.propTypes = {
  submitText: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
};

export default MailchimpForm;
