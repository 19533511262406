import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row, Card } from 'react-bootstrap';
import * as Globals from '../../globals';
import Img from 'gatsby-image';
import rhythm from '../utils/rhythm';
import NewsletterBanner from '../components/newsletterBanner/newsletterBanner';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const IndexPage = ({ data }) => {
  let headerBackgroundFluidImageStack = [
    data.contentfulSiteHome.headerBackground.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo 
        title={data.contentfulSiteHome.metaContent.title} 
        description={data.contentfulSiteHome.metaContent.description}
      />
      <BackgroundImage
        fluid={headerBackgroundFluidImageStack}
        className={`jumbotron section-background pt-0`}
        Tag={`header`}
      >
        <Container style={{ zIndex: 1, position: 'relative' }}>
          <Row>
            <Col md={10} lg={8}>
              <h1>{data.contentfulSiteHome.headerHeadline}</h1>
              <a
                href={`https://app.boardhealthscore.com/`}
                target={`_blank`}
                className={`btn btn-primary mt-3`}
              >
                {/*{data.contentfulSiteHome.headerButton}*/}
                Get Started
              </a>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>

      <section className={`my-5`}>
        <Container>
          <Row className={`mb-4`}>
            <Col xs={12} md={10}>
              <h2>{data.contentfulSiteHome.pitchHeadline}</h2>
            </Col>
          </Row>

          <Row className={`mb-4`}>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-start mb-3 mb-md-0`}
            >
              <div className={`mb-3`} style={{ width: rhythm(2) }}>
                <img
                  src={data.contentfulSiteHome.pitchBullet1Icon.file.url}
                  alt={`alt text`}
                />
              </div>
              <h2 className={`h4`}>
                {data.contentfulSiteHome.pitchBullet1Headline}
              </h2>
              <p className={`mb-3`}>
                {
                  data.contentfulSiteHome.pitchBullet1Content
                    .pitchBullet1Content
                }
              </p>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-start`}
            >
              <div className={`mb-3`} style={{ width: rhythm(2) }}>
                <img
                  src={data.contentfulSiteHome.pitchBullet2Icon.file.url}
                  alt={`alt text`}
                />
              </div>
              <h2 className={`h4`}>
                {data.contentfulSiteHome.pitchBullet2Headline}
              </h2>
              <p className={`mb-3`}>
                {
                  data.contentfulSiteHome.pitchBullet2Content
                    .pitchBullet2Content
                }
              </p>
            </Col>
          </Row>

          <div className={`text-center`}>
            <a href={`https://app.boardhealthscore.com/`} target={`_blank`} className={`btn btn-primary`}>
              {/*{data.contentfulSiteHome.pitchButton}*/}
              Get Started
            </a>
          </div>
        </Container>
      </section>

      <hr className={`m-0 w-50 mx-auto`} />

      <section className={`my-5`}>
        <Container>
          <Row className={`justify-content-center`}>
            <Col xs={12} md={10} lg={8}>
              <h2 className={`mb-3`}>
                {data.contentfulSiteHome.whatWeDoHeadline}
              </h2>
              <p className={`mb-4`}>
                {data.contentfulSiteHome.whatWeDoContent1.whatWeDoContent1}
              </p>
              <h2 className={`mb-3`}>
                {data.contentfulSiteHome.whatWeDoHeadline2}
              </h2>
              <p>{data.contentfulSiteHome.whatWeDoContent2.whatWeDoContent2}</p>
            </Col>
          </Row>
        </Container>
      </section>

      <hr className={`m-0 w-50 mx-auto`} />

      <section className={`my-5`}>
        <Container>
          <Row className={`mb-4`}>
            <Col xs={12} md={10}>
              <h2>{data.contentfulSiteHome.featuresHeadline}</h2>
            </Col>
          </Row>

          <Img
            fluid={data.contentfulSiteHome.featuresBackground.sizes}
            alt={`alt text`}
            className={`mb-5 mb-md-0`}
          />

          <Row className={`px-2 mb-4`} style={{ marginTop: `-${rhythm(5)}` }}>
            <Col xs={12} md={4} className={`d-flex flex-column`}>
              <Card style={{ height: '100%' }}>
                <Card.Body>
                  <Card.Title>
                    {data.contentfulSiteHome.featureBullet1Headline}
                  </Card.Title>
                  <Card.Text style={{ lineHeight: 1.2 }}>
                    {
                      data.contentfulSiteHome.featureBullet1Content
                        .featureBullet1Content
                    }
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={4} className={`d-flex flex-column`}>
              <Card style={{ height: '100%' }}>
                <Card.Body>
                  <Card.Title>
                    {data.contentfulSiteHome.featureBullet2Headline}
                  </Card.Title>
                  <Card.Text style={{ lineHeight: 1.2 }}>
                    {
                      data.contentfulSiteHome.featureBullet2Content
                        .featureBullet2Content
                    }
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={4} className={`d-flex flex-column`}>
              <Card style={{ height: '100%' }}>
                <Card.Body>
                  <Card.Title>
                    {data.contentfulSiteHome.featureBullet3Headline}
                  </Card.Title>
                  <Card.Text style={{ lineHeight: 1.2 }}>
                    {
                      data.contentfulSiteHome.featureBullet3Content
                        .featureBullet3Content
                    }
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className={`text-center`}>
            <Link
              to="/why-it-works/"
              className={`btn btn-primary mt-2 mt-md-4`}
            >
              {data.contentfulSiteHome.featuresButton}
            </Link>
          </div>
        </Container>
      </section>

      <hr className={`m-0 w-50 mx-auto`} />

      <section className={`my-5`}>
        <Container>
          <Row className={`justify-content-center`}>
            <Col xs={12} md={8} className={`text-center`}>
              <h2 className={`mb-4`}>
                {data.contentfulSiteHome.securityHeadline}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulSiteHome.securityContent.childMarkdownRemark
                      .html,
                }}
              />
            </Col>
          </Row>
          <Row className={`justify-content-center`}>
            {data.contentfulSiteHome.securityLogos.map(
              (securityLogo, index) => {
                return (
                  <div className={`m-3`} style={{ width: rhythm(4) }}>
                    <img
                      src={securityLogo.file.url}
                      key={securityLogo.id}
                      alt={`alt text`}
                    />
                  </div>
                );
              }
            )}
          </Row>
        </Container>
      </section>

      <section className={`bg-light py-4`}>
        <Container>
          <Row className={`align-items-center`}>
            <Col xs={12} md={8}>
              <h3 className={`mb-2`}>
                {data.contentfulSiteHome.nonProfitBannerHeadline}
              </h3>
              <h5>{data.contentfulSiteHome.nonProfitBannerSubheadline}</h5>
            </Col>
            <Col xs={10} md={4} className={`mx-auto mx-md-0 mt-2 mt-md-0`}>
              <AnchorLink
                to="/pricing#advisorPricing"
                className={`btn btn-primary d-block`}
              >
                {data.contentfulSiteHome.nonProfitBannerButton}
              </AnchorLink>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`my-5`}>
        <Container>
          <Row className={`align-items-center`}>
            <Col xs={12} md={5} className={`mb-3 mb-md-0`}>
              <img
                src={data.contentfulSiteHome.proofImage.file.url}
                alt={`alt text`}
              />
            </Col>

            <Col xs={12} md={7}>
              <Row className={`mb-4`}>
                <Col xs={10} md={9}>
                  <h3>
                    <span
                      className={`display-1 d-block mb-2`}
                      style={{ lineHeight: 1 }}
                    >
                      {data.contentfulSiteHome.proofStatistic}
                      <small>%</small>
                    </span>
                    <small className={`text-muted`}>
                      {data.contentfulSiteHome.proofHeadline.proofHeadline}
                    </small>
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <h2 className={`h3`}>
                    {data.contentfulSiteHome.proofSubheadline}
                  </h2>
                  <p
                    className={`mb-4`}
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulSiteHome.proofContent.childMarkdownRemark
                          .html,
                    }}
                  />
                  <Link to="/why-it-works/" className={`btn btn-primary`}>
                    {data.contentfulSiteHome.proofButton}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <NewsletterBanner />
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulSiteHome {
      metaContent {
        title
        description
      }
      headerHeadline
      headerButton
      headerBackground {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      pitchHeadline
      pitchBullet1Icon {
        file {
          contentType
          url
          fileName
        }
      }
      pitchBullet1Headline
      pitchBullet1Content {
        pitchBullet1Content
      }
      pitchBullet2Icon {
        file {
          contentType
          url
          fileName
        }
      }
      pitchBullet2Headline
      pitchBullet2Content {
        pitchBullet2Content
      }
      pitchButton
      whatWeDoHeadline
      whatWeDoContent1 {
        whatWeDoContent1
      }
      whatWeDoHeadline2
      whatWeDoContent2 {
        whatWeDoContent2
      }
      featuresHeadline
      featuresBackground {
        sizes(maxWidth: 1440) {
          ...GatsbyContentfulSizes
        }
      }
      featuresButton
      featureBullet1Headline
      featureBullet1Content {
        featureBullet1Content
      }
      featureBullet2Headline
      featureBullet2Content {
        featureBullet2Content
      }
      featureBullet3Headline
      featureBullet3Content {
        featureBullet3Content
      }
      securityHeadline
      securityContent {
        childMarkdownRemark {
          html
        }
      }
      securityLogos {
        file {
          contentType
          url
          fileName
        }
      }
      nonProfitBannerHeadline
      nonProfitBannerSubheadline
      nonProfitBannerButton
      proofImage {
        file {
          url
          fileName
          contentType
        }
      }
      proofStatistic
      proofHeadline {
        proofHeadline
      }
      proofSubheadline
      proofContent {
        childMarkdownRemark {
          html
        }
      }
      proofButton
    }
  }
`;

export default IndexPage;
